<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        <b>[A]&nbsp;</b> and <b>&nbsp;[B]&nbsp;</b> hydroxide are <b>&nbsp;[C]&nbsp;</b> which
        dissolve <b>&nbsp;[D]&nbsp;</b> in water. <b>[E]&nbsp;</b> hydroxide is
        <b>&nbsp;[F]&nbsp;</b> dissolved in water. These bases <b>&nbsp;[G]&nbsp;</b> the skin which
        <b>&nbsp;[H]&nbsp;</b> immediately painful. The skin <b>&nbsp;[I]&nbsp;</b> protective
        layers when corroded by bases.
      </p>

      <p v-for="(letter, index) in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          style="display: inline-block; width: 319px"
          class="my-1 ml-3"
          :items="allQuestionOptions[index]"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S1_Q11',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
      },
      allQuestionOptions: [
        [
          {text: 'Sodium', value: 'sodium'},
          {text: 'Calcium', value: 'calcium'},
          {text: 'Strontium', value: 'strontium'},
          {text: 'Iron', value: 'iron'},
        ],
        [
          {text: 'potassium', value: 'potassium'},
          {text: 'calcium', value: 'calcium'},
          {text: 'lithium', value: 'lithium'},
          {text: 'ammonium', value: 'ammonium'},
        ],
        [
          {text: 'white pellets', value: 'whitePellets'},
          {text: 'blue powder', value: 'bluePowder'},
          {text: 'cloudy liquids', value: 'cloudyLiquids'},
          {text: 'slightly yellow crystals', value: 'yellowCrystals'},
        ],
        [
          {text: 'exothermically', value: 'exothermically'},
          {text: 'sparingly', value: 'sparingly'},
          {text: 'easily', value: 'easily'},
          {text: 'endothermically', value: 'endothermically'},
        ],
        [
          {text: 'Potassium', value: 'potassium'},
          {text: 'Sodium', value: 'sodium'},
          {text: 'Ammonium', value: 'ammonium'},
          {text: 'Lithium', value: 'lithium'},
        ],
        [
          {text: 'ammonia gas', value: 'gas'},
          {text: 'ammonia solid', value: 'solid'},
          {text: 'ammonia liquid', value: 'liquid'},
        ],
        [
          {text: 'saponify', value: 'saponify'},
          {text: 'coagulate', value: 'coagulate'},
          {text: 'liquify', value: 'liquify'},
          {text: 'scar', value: 'scar'},
        ],
        [
          {text: 'may not be', value: 'mayNotBe'},
          {text: 'is', value: 'is'},
        ],
        [
          {text: 'does not form', value: 'doesNotForm'},
          {text: 'form', value: 'form'},
          {text: 'slowly form', value: 'slowlyForm'},
        ],
      ],
      letterOptions: [
        {text: '[A]: ', inputName: 'input1'},
        {text: '[B]: ', inputName: 'input2'},
        {text: '[C]: ', inputName: 'input3'},
        {text: '[D]: ', inputName: 'input4'},
        {text: '[E]: ', inputName: 'input5'},
        {text: '[F]: ', inputName: 'input6'},
        {text: '[G]: ', inputName: 'input7'},
        {text: '[H]: ', inputName: 'input8'},
        {text: '[I]: ', inputName: 'input9'},
      ],
    };
  },
};
</script>
